import React from 'react';
import styled from 'styled-components';
import Container from 'src/components/Container';
import useFromBreakpoint from 'src/hooks/useFromBreakpoint';
import { BreakpointKey } from 'src/styles/theme';
import SmallerLayout from './layouts/Smaller';
import LargerLayout from './layouts/Larger';
import { LayoutProps } from './types';

type Props = Omit<LayoutProps, 'mobileBreakpointName'> & {
  hide?: boolean,
  mobileBreakpointName?: BreakpointKey,
};

const NavHeader:React.FC<Props> = ({
  hide = false,
  hideNav = false,
  hideCta = false,
  mobileBreakpointName = 'tabletWide',
  ctaText = 'Get in touch',
}) => {
  const screenLargerThan = useFromBreakpoint('phoneWide');
  const Layout = screenLargerThan ? LargerLayout : SmallerLayout;
  if (hide) return null;
  return (
    <NavHeaderOuter>
      <Container>
        <Layout
          hideNav={hideNav}
          hideCta={hideCta}
          mobileBreakpointName={mobileBreakpointName}
          ctaText={ctaText}
        />
      </Container>
    </NavHeaderOuter>
  );
};
export default NavHeader;

const NavHeaderOuter = styled.div`
  position: relative;
  z-index: 2;
  .dark-bg & {
    color: ${(p) => p.theme.palette.darkBgText};
  }
`;
