/* eslint-disable @typescript-eslint/lines-between-class-members */
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { object, string } from 'yup';
import { Base } from './Base';


export interface ConstructorProps {
  contentHtml: string,
  metaDescription: string,
  title: string,
  slug: string,
  navTitle?: string,
  metaTitle?: string,
  featuredImage?: IGatsbyImageData,
}


export interface InstanceProps {
  slug: string,
  h1: string,
  navTitle: string,
  metaTitle: string,
  metaDescription: string,
  contentHtml: string,
  featuredImage?: IGatsbyImageData,
}


export class SitePage extends Base implements InstanceProps {
  slug: string;
  h1: string;
  metaDescription: string;
  contentHtml: string;
  featuredImage?: IGatsbyImageData;
  protected _navTitle?: string;
  protected _metaTitle?: string;

  // this checks for runtime empty strings as well as undefined vals
  static criticalInput = object({
    slug: string().required(),
    metaDescription: string().required(),
  }).required();

  constructor(props: ConstructorProps) {
    super();
    this.slug = props.slug;
    this.h1 = props.title;
    this._navTitle = props.navTitle;
    this._metaTitle = props.metaTitle;
    this.metaDescription = props.metaDescription;
    this.contentHtml = props.contentHtml;
    this.featuredImage = props.featuredImage;

    SitePage.criticalInput.validateSync(this);
  }

  get navTitle() {
    return this._navTitle || this.h1;
  }

  get metaTitle() {
    return this._metaTitle || this.h1;
  }
}
