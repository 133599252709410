import React from 'react';
import styled, { ThemeContext } from 'styled-components';
import BurgerButton from 'components/BurgerButton';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { setVisibility } from 'store/features/nav/main/navMainSlice';
import { scrollToPageTop, tagManagerDataLayerEventNames } from 'src/helpers';
import { getDataLayer } from 'src/lib/helpers';

export interface Props {}

const BurgerButtonNav:React.FC<Props> = () => {
  const theme = React.useContext(ThemeContext);
  const dispatch = useAppDispatch();
  const { isVisible } = useAppSelector((state) => state.navMain);
  const handleClick = async () => {
    const dataLayer = getDataLayer();
    dataLayer.push({
      event: tagManagerDataLayerEventNames.NAV_BURGER_BUTTON_CLICK,
      navOpen: !isVisible,
    });
    await scrollToPageTop();
    dispatch(setVisibility(!isVisible));
  };

  return (
    <BurgerButtonNavOuter>
      <BurgerButton
        isActive={isVisible}
        onClick={handleClick}
        color={isVisible ? theme.palette.primary : theme.palette.interactive}
      />
    </BurgerButtonNavOuter>
  );
};
export default BurgerButtonNav;

const BurgerButtonNavOuter = styled.div``;
