import { Variants } from 'components/Typography/types';

const useDynamicTitleVariant = (
  title: string,
  variantMap: Record<number, Variants> = {
    0: 'pageTitle',
    24: 'h1',
    50: 'h3',
  },
): Variants => {
  const h1Variant = Object.entries(variantMap).reduce<Variants>((acc, [charlimit, variant]) => (
    title.length > Number(charlimit) ? variant : acc
  ), variantMap[0]);
  return h1Variant;
};

export default useDynamicTitleVariant;
