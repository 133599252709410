import React from 'react';
import { Helmet } from 'react-helmet';
import useGeneralSettings from 'src/hooks/queries/useGeneralSettings';

interface Props {
  title: string,
  titlePath?: string | Array<string | undefined>,
  description: string,
  lang?: string,
  meta?: {
    name: string,
    content: string,
  }[],
}


const Seo:React.FC<Props> = ({
  title: titleNoPath,
  titlePath = [],
  description,
  lang = 'en',
  meta = [],
}) => {
  const generalSettings = useGeneralSettings();
  const titlePathArraySafe = Array.isArray(titlePath) ? titlePath : [titlePath];
  const titlePathArraySafeClean = titlePathArraySafe.filter((item) => item);
  const mainTitle = [
    ...titlePathArraySafeClean,
    titleNoPath,
  ].join(' | ');
  const conditionalSuffix = mainTitle.length <= 30 ? ` | ${generalSettings?.title || ''}` : '';
  const pageTitle = mainTitle + conditionalSuffix;
  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={pageTitle}
      meta={[
        {
          name: 'description',
          content: description,
        },
        {
          property: 'og:title',
          content: pageTitle,
        },
        {
          property: 'og:description',
          content: description,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: 'Blueclaw',
        },
        {
          name: 'twitter:title',
          content: pageTitle,
        },
        {
          name: 'twitter:description',
          content: description,
        },
      ].concat(meta)}
    />
  );
};

export default Seo;
