import loadable from '@loadable/component';
import React from 'react';
import useFromBreakpoint from 'src/hooks/useFromBreakpoint';
import { BreakpointKey } from 'src/styles/theme';
import { MobileDesktopNavSharedProps, NavItem } from './types';

export interface Props {
  navItems: NavItem[],
  mobileBreakpointName?: BreakpointKey,
}

const NavMenu:React.FC<Props> = ({
  navItems,
  mobileBreakpointName = 'tablet',
}) => {
  const isMobile = !useFromBreakpoint(mobileBreakpointName);
  const navProps: MobileDesktopNavSharedProps = { navItems, mobileBreakpointName };

  const NavComponent = React.useMemo(() => {
    if (typeof window === 'undefined' || !isMobile) return loadable(() => import('./desktop/DesktopNav'));
    else return loadable(() => import('./mobile/MobileNav'));
  }, [isMobile]);

  return (
    <NavComponent {...navProps} />
  );
};
export default NavMenu;
