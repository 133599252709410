import React from 'react';
import styled from 'styled-components';
import MainNav from 'components/MainNav';
import BurgerButtonNav from 'src/components/BurgerButtonNav';
import CompanyLogo from 'components/CompanyLogo';
import CtaButton from 'components/CtaButton';
import { BreakpointKey } from 'src/styles/theme';
import { LayoutProps } from '../types';

export type Props = LayoutProps;

const Larger:React.FC<Props> = ({
  mobileBreakpointName,
  hideNav,
  hideCta,
  ctaText,
}) => {
  return (
    <LargerOuter>
      <CompanyLogo withHomeLink />
      {!hideNav && <MainNav mobileBreakpointName={mobileBreakpointName} />}
      {!hideCta && !hideNav && (
        <CtaBurgerWrap>
          {!hideCta && <CtaButton type="primary" variant="ghost">{ctaText}</CtaButton>}
          {!hideNav && (
            <BurgerButtonWrap mobileBreakpointName={mobileBreakpointName}>
              <BurgerButtonNav />
            </BurgerButtonWrap>
          )}
        </CtaBurgerWrap>
      )}
    </LargerOuter>
  );
};
export default Larger;

const LargerOuter = styled.div`
  ${({ theme }) => theme.breakpoints.up('phoneWide')} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const BurgerButtonWrap = styled.div<{ mobileBreakpointName: BreakpointKey }>`
  position: relative;
  z-index: 3;
  ${(p) => p.theme.breakpoints.up(p.mobileBreakpointName)} {
    display: none;
  }
`;
const CtaBurgerWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
