import { useStaticQuery, graphql } from 'gatsby';

export default (): ReadonlyArray<GatsbyTypes.ServiceBasicFragment> => {
  const result = useStaticQuery<GatsbyTypes.ServicesForUseServicesQuery>(graphql`
    query ServicesForUseServices {
      allWpService(sort: {order: ASC, fields: menuOrder}) {
        nodes {
          ...ServiceBasic
        }
      }
    }
  `);
  return result.allWpService.nodes;
};
