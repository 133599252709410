import React from 'react';
import styled from 'styled-components';
import MainNav from 'components/MainNav';
import BurgerButtonNav from 'src/components/BurgerButtonNav';
import CompanyLogo from 'components/CompanyLogo';
import CtaButton from 'components/CtaButton';
import { BreakpointKey } from 'src/styles/theme';
import { LayoutProps } from '../types';

export type Props = LayoutProps;

const Smaller:React.FC<Props> = ({
  mobileBreakpointName,
  hideNav,
  hideCta,
  ctaText,
}) => {
  const logoRow = hideNav ? (
    <CompanyLogo withHomeLink />
  ) : (
    <LogoNavWrap>
      <CompanyLogo withHomeLink />
      <BurgerButtonWrap mobileBreakpointName={mobileBreakpointName}>
        <BurgerButtonNav />
      </BurgerButtonWrap>
    </LogoNavWrap>
  );
  const ctaRow = hideCta ? null : (
    <CtaButtonWrap>
      <CtaButton type="primary" variant="ghost">{ctaText}</CtaButton>
    </CtaButtonWrap>
  );
  return (
    <SmallerOuter>
      {logoRow}
      {ctaRow}
      <MainNav mobileBreakpointName={mobileBreakpointName} />
    </SmallerOuter>
  );
};
export default Smaller;

const SmallerOuter = styled.div`
  ${({ theme }) => theme.breakpoints.up('phoneWide')} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const BurgerButtonWrap = styled.div<{ mobileBreakpointName: BreakpointKey }>`
  position: relative;
  right: -20px;
  top: -20px;
  z-index: 3;
  ${(p) => p.theme.breakpoints.up(p.mobileBreakpointName)} {
    display: none;
  }
`;
const LogoNavWrap = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  ${({ theme }) => theme.breakpoints.up('phoneWide')} {
    align-items: center;
  }
  `;
const CtaButtonWrap = styled.div`
  ${({ theme }) => theme.breakpoints.down('phoneWide')} {
    display: flex;
    justify-content: center;
    background-color: ${(p) => p.theme.palette.interactive};
    margin: -10px -30px 0;
    padding: 3px;
    &&&& button {
      color: ${(p) => p.theme.palette.primary};
    }
  }
`;
