import React from 'react';
import styled, { ThemeContext } from 'styled-components';
import HoverEffect from '../HoverEffect';

export interface Props {
  isActive: boolean,
  onClick: () => void,
  color?: React.CSSProperties['backgroundColor'],
  size?: number,
}

const BurgerButton:React.FC<Props> = ({
  isActive,
  onClick,
  color: colorProp,
  size = 4,
}) => {
  const theme = React.useContext(ThemeContext);
  const color = colorProp || theme.palette.interactive;
  const barProps = { size, $color: color };
  return (
    <BurgerButtonOuter
      onClick={onClick}
      type="button"
      className={isActive ? 'show-cross' : 'show-burger'}
    >
      <HoverEffect scale underline={false}>
        <Bar {...barProps} className="top" />
        <Bar {...barProps} className="middle" />
        <Bar {...barProps} className="bottom" />
      </HoverEffect>
    </BurgerButtonOuter>
  );
};
export default BurgerButton;

const BurgerButtonOuter = styled.button`
  padding: 20px;
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  overflow: hidden;
`;

const spacingMultiplier = 1;
const getTranslationVal = (size: number): number => size + (size * spacingMultiplier);
const Bar = styled.div<{ $color: Props['color'], size: number }>`
  width: 30px;
  height: ${(p) => p.size}px;
  margin-bottom: ${(p) => p.size * spacingMultiplier}px;
  background-color: ${(p) => p.$color};
  border-radius: 2px;
  transition-property: opacity, transform, background-color;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  &.bottom { margin-bottom: 0; }
  ${BurgerButtonOuter}.show-cross & {
    &.top {
      transform:
        translateY(${(p) => getTranslationVal(p.size)}px)
        rotate(-45deg);
    }
    &.middle {
      transform: translateX(-100%);
      opacity: 0;
    }
    &.bottom {
      transform:
        translateY(${(p) => getTranslationVal(p.size) * -1}px)
        rotate(45deg);
    }
  }
`;
