import { useAppDispatch } from 'store/hooks';
import React from 'react';
import { setVisibility } from 'store/features/ctaOverlay/ctaOverlaySlice';
import Button, { PropsButton as ButtonProps } from 'components/Button';
import { tagManagerDataLayerEventNames } from 'src/helpers';
import { getDataLayer } from 'src/lib/helpers';

type Props = Omit<ButtonProps, 'onClick'> & {
  className?: string,
  onClick?: ButtonProps['onClick'],
};

const CtaButton:React.FC<Props> = ({
  children = 'Get in touch',
  className,
  onClick: onClickProp,
  ...restProps
}) => {
  const dispatch = useAppDispatch();
  const handleClick = () => dispatch(setVisibility(true));
  const clickWithDataLayerEvent = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const dataLayer = getDataLayer();
    dataLayer.push({
      event: tagManagerDataLayerEventNames.MAIN_CTA_BUTTON_CLICK,
    });
    if (onClickProp) onClickProp(e);
    else handleClick();
  };
  return (
    <Button
      {...restProps}
      onClick={clickWithDataLayerEvent}
      className={className}
    >
      {children}
    </Button>
  );
};
export default CtaButton;
