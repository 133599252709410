import classNames from 'classnames';
import React from 'react';
import styled, { ThemeContext } from 'styled-components';

interface HoverType {
  underline?: boolean,
  underlineFade?: boolean,
  scale?: boolean | string,
}

export type Props = HoverType & {
  children: React.ReactNode,
  color?: React.CSSProperties['backgroundColor'],
  underlineHeight?: React.CSSProperties['height'],
  disabled?: boolean,
  alwaysOn?: boolean,
};

const HoverEffect:React.FC<Props> = ({
  children,
  underline = true,
  underlineFade = false,
  scale = false,
  color: colorProp,
  underlineHeight = '2px',
  disabled = false,
  alwaysOn = false,
}) => {
  const theme = React.useContext(ThemeContext);
  const color = colorProp || theme.palette.primary;
  return (
    <HoverEffectOuter
      className={disabled ? 'disabled' : classNames([
        { underline },
        { underlineFade },
        { scale },
        { alwaysOn },
      ])}
      $color={color}
      $scale={scale}
      underlineHeight={underlineHeight}
    >
      {children}
    </HoverEffectOuter>
  );
};
export default HoverEffect;

interface HoverEffectOuterProps {
  $color: Props['color'],
  $scale: Props['scale'],
  underlineHeight: Props['underlineHeight'],
}
/* eslint-disable @typescript-eslint/indent */
const HoverEffectOuter = styled.span<HoverEffectOuterProps>`
  display: inline-block;
  &.scale {
    transform: scale(1.0000001);
    transition: transform 0.2s;
    transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
    &:hover,
    &.alwaysOn {
      transform: scale(${({ $scale }) => (
        typeof $scale === 'string' ? $scale : '1.1')}
      );
    }
  }
  &.underline,
  &.underlineFade {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      bottom: -${(p) => p.underlineHeight};
      bottom: calc((${(p) => p.underlineHeight} * -1) - 0.15em);
      left: 0;
      width: 100%;
      height: ${(p) => p.underlineHeight};
      background-color: ${(p) => p.$color};
      transition-property: opacity, transform;
      transition-duration: 0.2s;
      transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
    }
  }
  &.underline {
    &:before {
      transform-origin: left;
      transform: scaleX(0);
    }
    &:hover,
    &.alwaysOn {
      &:before { transform: scaleX(1); }
    }
  }
  &.underlineFade {
    &:before { opacity: 0; }
    &:hover,
    &.alwaysOn {
      &:before { opacity: 1; }
    }
  }
`;
/* eslint-enable @typescript-eslint/indent */
