import { useStaticQuery, graphql } from 'gatsby';

export default (): ReadonlyArray<GatsbyTypes.IndustryBasicFragment> => {
  const result = useStaticQuery<GatsbyTypes.IndustriesForUseIndustriesQuery>(graphql`
    query IndustriesForUseIndustries {
      allWpIndustry(sort: {order: ASC, fields: menuOrder}) {
        nodes {
          ...IndustryBasic
        }
      }
    }
  `);
  return result.allWpIndustry.nodes;
};
