import { SitePage } from 'models/SitePage';

interface RequiredInput {
  slug?: string,
  title?: string,
  content?: string,
  featuredImage?: GatsbyTypes.FeaturedImageThumbnailFragment,
  acfSeo?: {
    navTitle?: string,
    metaTitle?: string,
    metaDescription?: string,
  },
}

export default (queryData: RequiredInput) => {
  return new SitePage({
    slug: queryData.slug || '',
    title: queryData.title || '',
    navTitle: queryData?.acfSeo?.navTitle,
    metaTitle: queryData?.acfSeo?.metaTitle,
    contentHtml: queryData.content || '',
    featuredImage: queryData?.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    metaDescription: queryData.acfSeo?.metaDescription || '',
  });
};
