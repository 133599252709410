import React from 'react';
import styled from 'styled-components';
import NavMenu from 'components/NavMenu';
import { resolveUrl } from 'src/helpers';
import useIndustries from 'src/hooks/queries/useIndustries';
import useServices from 'src/hooks/queries/useServices';
import { BreakpointKey } from 'src/styles/theme';
import BasicQuery_SitePage from 'src/transformers/BasicQuery_SitePage';
import { NavItem as NavItemType } from './NavMenu/types';

interface Props {
  mobileBreakpointName?: BreakpointKey,
}

const MainNav:React.FC<Props> = ({
  mobileBreakpointName = 'tablet',
}) => {
  const pageDataIndustries = Array.from(useIndustries(), BasicQuery_SitePage);
  const pageDataServices = Array.from(useServices(), BasicQuery_SitePage);
  const data: NavItemType[] = [
    {
      id: 'f1233275-7ffc-5c7a-8cca-c7d78a8c278c',
      href: resolveUrl.service(''),
      label: 'Services',
      children: pageDataServices.map((service) => ({
        id: service._id,
        href: resolveUrl.service(service.slug),
        label: service.navTitle,
      })),
    },
    {
      id: '5ea21062-26b1-5c57-b9d3-5790e7e2be51',
      href: resolveUrl.industry(''),
      label: 'Industries',
      children: pageDataIndustries.map((industry) => ({
        id: industry._id,
        href: resolveUrl.industry(industry.slug),
        label: industry.navTitle,
      })),
    },
    {
      id: '579471f2-b97c-5756-86a0-908db7c84c3c',
      href: resolveUrl.caseStudy(''),
      label: 'Case Studies',
    },
    {
      id: 'd42c786c-7e15-5805-b02e-5a1b83910cf2',
      href: resolveUrl.blog(''),
      label: 'Blog',
    },
    {
      id: 'bb0365c7-b06a-559f-b8e5-efe9b50e4c70',
      href: resolveUrl.about(''),
      label: 'About',
      children: [{
        id: '7dbc3ade-558d-56b3-a579-ad4e72f98ef7',
        href: resolveUrl.career(''),
        label: 'Careers',
      }],
    },
    {
      id: '7b91cf51-c86f-5341-9dc4-fd083ddcc81f',
      href: resolveUrl.contact(''),
      label: 'Contact',
    },
  ];

  return (
    <MainNavOuter>
      <NavMenu navItems={data} mobileBreakpointName={mobileBreakpointName} />
    </MainNavOuter>
  );
};
export default MainNav;

const MainNavOuter = styled.div``;
